import ImageViewer from 'react-simple-image-viewer';
import React, { useState, useCallback } from 'react';
const AGROFORESTRY = () => {
  const path = '/en/AGROFORESTRY/';
  const startFrom =0;
  const endToPage = 62;
    const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
   
  ];
   for(let i=startFrom; i<=endToPage; i++) {
    images.push(`${path}x-${i}.jpg`);
   }
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

    return (
        <div>
           {images.map((src, index) => (
             <img
               src={ src }
               onClick={ () => openImageViewer(index) }
               width="300"
               key={ index }
               style={{ margin: '2px' }}
               alt=""
             />
           ))}
     
           {isViewerOpen && (
             <ImageViewer
               src={ images }
               currentIndex={ currentImage }
               disableScroll={ false }
               closeOnClickOutside={ true }
               onClose={ closeImageViewer }
             />
           )}
         </div>
       );
  };
  
  export default AGROFORESTRY;