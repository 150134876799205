import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <nav>
        <ul className="menu">
          <li className="item">
            <Link to="/">प्राकृतिक खेती</Link>
          </li>
          <li>
            <Link to="/en-natural-farming">Natural Farming</Link>
          </li>
          <li>
            <Link to="/agro-forestry">Agro Forestry</Link>
          </li>
          
        </ul>
      </nav>

      <Outlet />
    </>
  )
};

export default Layout;