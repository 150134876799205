import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout';
import NaturalFarming from './NaturalFarming';
import AGROFORESTRY from './AGROFORESTRY';
import EnNaturalFarming from './EnNaturalFarming';
import NoPage from './NoPage';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<NaturalFarming />} />
          <Route path="agro-forestry" element={<AGROFORESTRY />} />
          <Route path="en-natural-farming" element={<EnNaturalFarming />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
